import { StyleContainer } from "../../components/Common/StyleContainer";
import { Box, Typography } from "@mui/material";
import blog1 from "../../assets/blogs/blog-1.svg";
import blog2 from "../../assets/blogs/blog-2.svg";
import blog3 from "../../assets/blogs/blog-3.svg";
import { useAppBreakpoints } from "../../hooks/useAppBreakpoints";

function Blogs() {
  const blogs = [
    {
      pic: blog1,
      label: "The Path to the Holy Grail: Solving the Stablecoin Trilemma",
      href: "https://medium.com/@purecash_labs/the-path-to-the-holy-grail-solving-the-stablecoin-trilemma-b4c080ff89fc",
    },
    {
      pic: blog2,
      label:
        "Revolutionizes Tokenomics: 100% Airdrop to the Community at once with Continuous Burn",
      href: "https://medium.com/@purecash_labs/pure-cash-revolutionizes-tokenomics-100-airdrop-to-the-community-at-once-with-continuous-burn-79cac28e56cb",
    },
    {
      pic: blog3,
      label:
        "ETH-Backed Decentralized Stablecoins: The Key to Restoring ETH’s Lost Value",
      href: "https://medium.com/@purecash_labs/eth-backed-decentralized-stablecoins-the-key-to-restoring-eths-lost-value-bcec0e2acd68",
    },
  ];

  const { isMatchMobile } = useAppBreakpoints();

  return (
    <StyleContainer className="mt-[80px] sm:mt-[40px] ">
      <Typography
        className="mb-[30px] leading-[1.5] sm:mb-[20px]"
        fontSize={isMatchMobile ? 20 : 32}
        fontWeight={600}
      >
        Theory
      </Typography>
      <div className="grid grid-cols-3 w-full gap-[12px] sm:block sm:space-y-[12px]">
        {blogs.map((blog, index) => (
          <Box
            key={index}
            sx={{
              background: "#F7F7F6",
              border: "1px solid #F7F7F6",
              "&:hover": {
                border: "1px solid rgba(78, 78, 78, 0.70)",
                cursor: "pointer",
              },
            }}
            onClick={() => {
              window.open(blog.href, isMatchMobile ? "_self" : "_blank");
            }}
            className="col-span-1 rounded-xl p-0.75"
          >
            <img
              className="rounded-t-xl"
              width="100%"
              height={100}
              src={blog.pic}
              alt=""
            />
            <Typography
              className="px-1.5 sm:px-0 mt-1.5 mb-0.5"
              fontSize={isMatchMobile ? 16 : 18}
              fontWeight={400}
              color="text.secondary"
            >
              {blog.label}
            </Typography>
          </Box>
        ))}
      </div>
    </StyleContainer>
  );
}

export default Blogs;
